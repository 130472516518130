.header {
    min-height: 105px;
    width: 100vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 2;
}
