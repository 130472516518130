.lang-switcher {
    margin-top: 25px;
    margin-bottom: 25px;
}
.lang-switcher-option {
    cursor: pointer;
    color: #ffffff;
    font-weight: normal;
    text-decoration: none;
}
.lang-switcher-option:hover {
    font-family: Gotham-Bold, Arial, sans-serif;
}
.lang-switcher-option:active, .lang-switcher-option.active {
    font-family: Gotham-Bold, Arial, sans-serif;
    color: #ffffff;
}
.lang-switcher-option:visited {
    color: #ffffff;
}
.lang-switcher-option:focus {
    font-family: Gotham-Bold, Arial, sans-serif;
    color: #ffffff;
}
