
.footer-logo {
    max-width: 1280px;
    margin: auto;
}

.footer-logo-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    height: auto;
}
@media screen and (max-width: 576px) {

}
