.case-logo-area {
    width: 100%;
    margin-top: 100px;
}

.case-logo-area-top {
    width: 100%;
    margin-top: 50px;
}

.case-logo-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    height: auto;
}
@media screen and (max-width: 940px) {
    .case-logo-img {
        width: 80%;
    }
}
