.main-stage-view,
.main-stage-view > div{
    width: 100%;
    height: 720px;
    max-width: 1280px;
    display: flex;
    overflow: hidden;
    flex-flow: column;
    align-items: stretch;
    position: relative;
    margin: 0 auto;
    align-content: center;
    justify-content: center;

}


@media screen and (max-width: 1280px) {

    .main-stage-view,
    .main-stage-view > div{
        height: 100%;
    }
}


.mejs__mediaelement,
.mejs__mediaelement div {
    position: static !important;
    display: grid;
}


.mejs__container {
    height: 100% !important;
}
.main-stage-view  img {
    max-width: 1280px;
    height: auto;
}

.main-stage-view video:not(.mejs__container-fullscreen *) {
    max-width: 1280px;
    height: auto !important;
    object-fit: contain;
}

.visual-to-place-in-stage {
    width: 100%;
}

@media screen and (max-width: 1280px) {
    .main-stage-view {
        width: 90% !important;
    }
}
@media screen and (max-width: 576px) {


    .main-stage-view {
        width: 90% !important;
    }

    .main-stage-view  img {
        max-width: 100%;
    }

    .main-stage-view  video {
        max-width: 90%;
    }
}

