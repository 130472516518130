.text-block {
    margin-bottom: 20px;
}

.text-block:last-child {
    margin-bottom: 40px;
}

.text-block-headline {
    /*color: #ffffff;*/
    font-size: 18px;
    line-height: 22px;
    font-family: Gotham-Bold;
    margin-bottom: 4px;
}
.text-block-headline::before {
    content: "| ";
    margin-left: -10px;
}
.text-block-text {
    /*color: #ffffff;*/
    font-size: 16px;
    line-height: 22px;
}





.text-block-text p {
    margin-bottom: 6px;
}

.text-blocks img {
    max-width: 100%;
    display: block;
}

.teaserBgImage {
    display: none !important;
}

@media screen and (max-width: 576px) {
    .teaserBgImage {
        display: block !important;
        margin-bottom: 20px;
    }
}
