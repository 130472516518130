.background-container {
    height: 100%; /* You must set a specified height */
    width: 100%;
    background-position: center 800px;
    background-repeat: no-repeat;
    background-size: contain;
}

@media screen and (max-width: 576px) {
    .background-container {
        background-position: center bottom;
    }
}
