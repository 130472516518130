.text-blocks {
    margin: 25px auto 0 auto;
    display: flex;
    flex-direction: row;
    width: 78%;
    max-width: 1250px;
}
.column-left {
    flex: 40%;
    padding: 0 25px;
}
.column-right {
    flex: 60%;
    padding: 0 25px;
}

.text-blocks div {
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
}



@media screen and (max-width: 576px) {
    .text-blocks {
        width: 90%;
        display: block;
    }

    .text-blocks div {
        background-image: none !important;
    }
}