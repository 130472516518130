.carousel {
    position: relative;
    max-width: 1250px;
    margin: 25px auto 50px auto;
}
.swiper {
    width: 50%;
    height: 100%;
}
.swiper-wrapper {
    padding-bottom: 35px;
}
.swiper-wrapper-horizontal > .swiper-pagination {
    position: absolute;
    bottom: 0;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    width: 191px !important;
    height: 100px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    overflow: hidden;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border: 2px solid #ffffff;
}
.swiper-slide-active {

}

.main-stage-view  > div {
    position: relative;
    width: 100%;
    height: 100%;
}

.main-stage-view  > div {
    position: relative;
    width: 100%;
    height: 100%;
}



/* Pause background-position: -20px 0; */

.swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}


.swiper-pagination-bullet {
    background-color: #ffffff;
}
.swiper-pagination-bullet-active {
    background-color: #ffffff;
}
.carousel-navigation {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 22%;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carousel-previous {
    left: 19%;
    border-right: 2px solid #ffffff;
}
.carousel-previous:after {
    font-family: swiper-icons;
    content: "prev";
    font-size: 25px;
}
.carousel-next {
    right: 19%;
    border-left: 2px solid #ffffff;
}
.carousel-next:after {
    font-family: swiper-icons;
    content: "next";
    font-size: 25px;
}
@media screen and (max-width: 576px) {

    .carousel-navigation{
        display: none;
    }

    .swiper {
        width: 90%;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}


