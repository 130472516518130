p, span, a, h1, h2, h3, h4, h5, h6, img {
    color: inherit;
}


.errormsg {
    text-align: center;
    margin: 20% auto auto;
}

.errormsg p {
    padding: 20px;
}

.preview_password {
    position:fixed;
    left: 50%;
    top:30%;
    margin-left:-150px;
    padding:15px;
    width:300px;
    border:1px solid black;
    background-color: #fff;
    z-index: 1;
    color: #000000;
}
.preview_password label {
    display:block;
    margin-bottom:10px;
}
.preview_password input {
    margin:0 10px 10px 0;
}

#preview_password_error {
    display:none;
    color: red;
    padding: 4px 0;
}